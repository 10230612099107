import { Component } from "@angular/core";
import { DatabaseService } from "../database.service";

@Component({
  selector: "app-tab-feed",
  templateUrl: "tab-feed.page.html",
  styleUrls: ["tab-feed.page.scss"]
})
export class TabFeedPage {
  constructor(private db: DatabaseService) {}
  places = this.db.db().valueChanges();
}
