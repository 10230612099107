import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { AngularFirestore } from "@angular/fire/firestore";

@Injectable({
  providedIn: "root"
})
export class DatabaseService {
  place = {
    description: `Lorem ipsum dolor sit amet, per forensibus deseruisse ad.
    Id sea dicun adipiscing contentiones, duis elitr expetenda vel ut.
    Summo omnes quaerendum vim no, ei eam ullum consequuntur, ius magna melius percipitur id.
    Ex pri quem tacimates, diam duis et sea. Eu dico unum sit, ubique delicatissimi ut per.
    Pri nisl ullamcorper et. Lorem ipsum dolor sit amet, per forensibus deseruisse ad.
    Id sea dicunt adipiscing contentiones, duis elitr expetenda vel ut.
    Summo omnes quaerendum vim no, ei eam ullum consequuntur, ius magna melius percipitur id.
    Ex pri quem tacimates, diam duis et sea. Eu dico unum sit, ubique delicatissimi ut per. Pri nisl ullamcorper et.`,
    windSpeed: Number(Math.random() * 10).toFixed(1),
    windDirection: Number(Math.random() * 360).toFixed(0),
    precipitation: Number(Math.random() * 100).toFixed(0),
    temperature: Number(10 + Math.random() * 25).toFixed(0)
  };

  places = [
    { title: "soap" },
    { title: "soap" },
    { title: "soap" },
    { title: "soap" }
  ];
  constructor(private afs: AngularFirestore) {}

  listPlaces() {
    return of(this.places);
  }

  getPlace(id: string) {
    return this.afs.doc(`/places/${id}`);
  }

  db() {
    return this.afs.collection("/places");
  }
}
