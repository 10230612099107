import { IonicModule } from "@ionic/angular";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { TabMapPage } from "./tab-map.page";
// import { MapComponent } from "../map/map.component";
import { MapComponentModule } from "../map/map.module";

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    MapComponentModule,
    RouterModule.forChild([{ path: "", component: TabMapPage }])
  ],
  declarations: [TabMapPage]
})
export class TabMapPageModule {}
