import { IonicModule } from "@ionic/angular";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { TabFeedPage } from "./tab-feed.page";

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    RouterModule.forChild([
      {
        path: "",
        component: TabFeedPage
      },
      {
        path: "place-detail/:id",
        loadChildren:
          "../place-detail/place-detail.module#PlaceDetailPageModule"
      }
    ])
  ],
  declarations: [TabFeedPage]
})
export class TabFeedPageModule {}
